import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RulesComponent } from './pages/rules/rules.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { StatsComponent } from './pages/stats/stats.component';

import { GameComponent } from './pages/game/game.component';
import { MapComponent } from './pages/map/map.component';
import { LandmarksComponent } from './pages/landmarks/landmarks.component';
import { LandmarksGuard } from './guards/landmarks.guard';
import { LandmarksMapGuard } from './guards/landmarks-map.guard';
import { CityComponent } from './pages/city/city.component';
import { ChangeStatsComponent } from './pages/change-stats/change-stats.component';
import { ChangeComponent } from './pages/change-stats/change/change.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminAreaComponent } from './pages/admin-area/admin-area.component';
import { AdminGuard } from './guards/admin.guard';
import { AboutComponent } from './pages/about/about.component';
import { AccountComponent } from './pages/account/account.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { RemoveAdsComponent } from './pages/remove-ads/remove-ads.component';
import { ReplayComponent } from './pages/replay/replay.component';

const routes: Routes = [
  { path: '', component: GameComponent },
  {
    path: 'rules', component: RulesComponent,
  },
  {
    path: 'stats', component: StatsComponent,
  },
  {
    path: 'about', component: AboutComponent,
  },
  {
    path: 'settings', component: SettingsComponent,
  },
  {
    path: 'account', component: AccountComponent,
  },
  {
    path: 'map', component: MapComponent,
    canActivate: [LandmarksMapGuard],
  },
  {
    path: 'landmarks',
    component: LandmarksComponent,
    canActivate: [LandmarksGuard],
  },
  {
    path: 'city',
    component: CityComponent,
    canActivate: [LandmarksGuard],
  },
  {
    path: 'fix-stats',
    component: ChangeStatsComponent,
  },
  {
    path: 'fix-stats/change',
    component: ChangeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: []
  },
  {
    path: 'archive',
    component: ArchiveComponent,
  },
  {
    path: 'remove-ads',
    component: RemoveAdsComponent,
  },
  {
    path: 'replay',
    component: ReplayComponent,
  },
  {
    path: 'admin/portal',
    component: AdminAreaComponent,
    canActivate: [AdminGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  static forChild(): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
    throw new Error('Method not implemented.');
  }
}
