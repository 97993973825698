import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { EndComponent } from '../dialogs/end/end.component';
import { EnlargeImageComponent } from '../dialogs/enlarge-image/enlarge-image.component';
import { LandmarkImageComponent } from '../dialogs/landmark-image/landmark-image.component';
import { WelcomeComponent } from '../dialogs/welcome/welcome.component';
import { WeLoggedYouOutComponent } from '../dialogs/we-logged-you-out/we-logged-you-out.component';
import { ErrorComponent } from '../dialogs/error/error.component';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  private image = new BehaviorSubject("");

  constructor(public dialog: MatDialog) { }

  getImageSrc() : Observable<string> {
    return this.image.asObservable();
  }

  updateImage(val: string) {
    let img = this.image.value;
    img = val;
    this.image.next(img);
  }

  openEndDialog() {
    this.dialog.open(EndComponent, {
      width: '600px',
      autoFocus: false
    });
  }

  openLandmarkImage() {
    this.dialog.open(LandmarkImageComponent, {
      width: '600px',
      autoFocus: false
    });
  }

  openWelcome() {
    this.dialog.open(WelcomeComponent, {
      width: '600px',
      autoFocus: false
    });
  }

  openEnlargeImage() {
    this.dialog.open(EnlargeImageComponent, {
      width: '992px',
      autoFocus: false
    });
  }

  openWeLoggedYouOut() {
    this.dialog.open(WeLoggedYouOutComponent, {
      width: '600px',
      autoFocus: false
    });
  }

  openError() {
    this.dialog.open(ErrorComponent, {
      width: '600px',
      autoFocus: false
    });
  }
}
