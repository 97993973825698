import { Component, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.passwordEntered();
  }

  input = new UntypedFormControl('');
  showAlert: boolean = false;

  constructor(private router: Router) {}

  passwordEntered() {
    const hash = this.hashCode(this.input.value);
    if (hash === 700452780 || hash === 3515572) {
      this.showAlert = false;
      localStorage.setItem("admin", "true")
      this.router.navigate(['/admin/portal'])
    } else {
      this.showAlert = true;
    }
  }

  hashCode(str: string) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash;
  }

}
// t)~cVc8PyQ^y.5hGaq@D
